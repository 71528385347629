
const supportsTouch = () => (
  ('ontouchstart' in document.documentElement)
);

const supportsObjectFit = () => (
  ('objectFit' in document.documentElement.style) && !(/Edge/.test(navigator.userAgent))
);

const supportsIntersectionObserver = () => (
  (
    'IntersectionObserver' in window
      && 'IntersectionObserverEntry' in window
      && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
  )
);

// https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
const isOldIE = () => (
  (
    navigator.userAgent.indexOf('MSIE') !== -1
    || navigator.appVersion.indexOf('Trident/') > -1
  )
);

export {
  supportsTouch,
  supportsObjectFit,
  supportsIntersectionObserver,
  isOldIE
};
