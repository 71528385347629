import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  static targets = ["error", "success", "wrapper"];

  connect() {
    this.original = this.wrapperTarget.innerHTML;
  }

  async submit(event) {
    event.preventDefault();
    this.errorTarget.classList.remove("show");

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      let response = await submitForm(form);

      if (response.success) {
        this.wrapperTarget.classList.toggle("hide");
        this.successTarget.classList.toggle("show");
        setTimeout(() => {
          this.wrapperTarget.innerHTML = this.original;
          this.wrapperTarget.classList.remove("hide");
          this.successTarget.classList.remove("show");
        }, 2000);
      } else {
        this.errorTarget.classList.toggle("show");
      }
    }
  }

}
