const videoIsPlaying = (video) => {
  return (
    (
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    )
  )
}

export {
  videoIsPlaying
};
