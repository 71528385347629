/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'init';
import 'carillon';

// global components here, other components will be loaded based on template
import 'components/background_pattern/background_pattern'
import 'components/booking_bar/booking_bar'
import 'components/booking_calendar/booking_calendar'
import 'components/booking_widget/booking_widget'
import 'components/button/button'
import 'components/button_group/button_group'
import 'components/callout_card/callout_card'
import 'components/callout_popup/callout_popup'
import 'components/call_to_action_block/call_to_action_block'
import 'components/cookie_banner/cookie_banner'
import 'components/contact_bar/contact_bar'
import 'components/inline_call_to_action/inline_call_to_action'
import 'components/mobile_call_to_actions/mobile_call_to_actions'
import 'components/footer/footer'
import 'components/head_prefetch/head_prefetch'
import 'components/head_preload/head_preload'
import 'components/header/header'
import 'components/inline_call_to_action/inline_call_to_action'
import 'components/inline_svg_icon/inline_svg_icon'
import 'components/language_switcher/language_switcher'
import 'components/media/media'
import 'components/menu_card/menu_card'
import 'components/mobile_call_to_actions/mobile_call_to_actions'
import 'components/navis_dynamic_number/navis_dynamic_number'
import 'components/newsletter_signup_form/newsletter_signup_form'
import 'components/page/page'
import 'components/promo_bar/promo_bar'
import 'components/ruled_specs/ruled_specs'
import 'components/seo_head/seo_head'
import 'components/share/share'
import 'components/share_block/share_block'
import 'components/skip_to_main_content_link/skip_to_main_content_link'
import 'components/social_icons/social_icons'
import 'components/utility_text_block/utility_text_block'
import 'components/utility_card/utility_card'
import 'components/utility_grid/utility_grid'
import 'components/utility_panel_block/utility_panel_block'
import 'components/wysiwyg/wysiwyg'
