
const getUrlParams = (url = window.location.href) => {
  // stolen from:
  // * https://gomakethings.com/getting-all-query-string-values-from-a-url-with-vanilla-js/
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

const turbolinksChangeUrl = (location) => {
  if (window.Turbolinks) {
    Turbolinks
    .controller
    .pushHistoryWithLocationAndRestorationIdentifier(location, Turbolinks.uuid());
  }
};

export {
  getUrlParams,
  turbolinksChangeUrl
};
