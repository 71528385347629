module.exports = {

  // colors
  colorWhite: '#ffffff',
  colorOffWhite: '#f6f6f6',
  colorAlabaster: '#fafafa',
  colorBarleyCorn: '#a79b62',
  colorOffBlack: '#1a1a1a',
  colorMercury: '#e1e1e1',
  colorBlack: '#000000',
  colorIron: '#CCCCCC',
  colorBoston: '#4698B5',

  // carillon colors
  // names from http://chir.ag/projects/name-that-color/#484F62
  carillonRiverBed: '#484f62',
  carillonHippieBlue: '#6498AE',
  carillonDoveGrey: '#676767',
  carillonCasper: '#b2c6d3',
  carillonMercury: '#e1e1e1',
  carillonBarleyCorn: '#a79b62',

  // carillon fonts stack
  carillonSerif: '"FreightDisp Pro Book Italic-Black", serif',
  carillonSansSerif: '"Mark Pro", sans-serif',

  // waldhaus colors
  // names from http://chir.ag/projects/name-that-color/#776d3c
  waldhausYellowMetal: '#776d3c',
  waldhausDoveGrey: '#676767',
  waldhausTundora: '#454545',
  waldhausMercury: '#e1e1e1',
  waldhausBarley: '#a79b62',

  // waldhaus fonts stack
  waldhausSerif: '"BigCaslon-Rom", serif',
  waldhausSansSerif: '"AvenirNextLTW01-Regular", sans-serif',

  // units
  spacing: '20px',

  // breakpoints
  breakpointMobile: '480px',
  breakpointTablet: '768px',
  breakpointTabletLandscape: '1024px',
  breakpointDesktop: '1400px',
  breakpointDesktopLarge: '1600px',
  breakpointDesktopXLarge: '1800px',

  // contingent breakpoints
  breakpointScrollHeader: '1775px',

  // container(s)
  containerGutter: '20px',
  containerXxxlarge: '2000px',
  containerXxlarge: '1600px',
  containerXlarge: '1400px',
  containerLarge: '1280px',
  containerDesktop: '1024px',
  containerPhablet: '768px',
  containerBookingBar: '900px',
  containerBookingCalendar: '400px',
  containerBookingCalendarMulti: '800px',
  containerHeadline: '800px',
  containerHeadlineLarge: '1024px',
  containerMedium: '680px',
  containerSmall: '480px',
  containerXsmall: '420px',
  containerXxsmall: '320px',
  containerXxxsmall: '200px',

  // offset(s) / measures
  offsetContactBar: '40px',
  offsetMobileActions: '80px',
  minBillboardHeight: '480px',
  maxBillboardHeight: '600px',
  maxBillboardHeightMobile: '400px',
  maxBillboardNoticeWidth: '500px',

  // transitions
  transitionDuration: 200,
  transitionEaseOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
  transitionEaseOutQuad: 'cubic-bezier(0.5, 1, 0.89, 1)',
  transitionEaseOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  transitionEaseOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  transitionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  transitionEaseOutExpo: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  transitionEaseInOutExpo: 'cubic-bezier(1, 0, 0, 1)',

  // zIndex(s)
  zIndexGlobalSkipToContentLink: 110,
  zIndexGlobalPopup: 100,
  zIndexGlobalCookieBanner: 95,
  zIndexGlobalHeaderDrawer: 92,
  zIndexGlobalContactBar: 90,
  zIndexGlobalMobileCallToActions: 85,
  zIndexGlobalHeader: 80,
  zIndexGlobalHeaderSubnavigation: 75,
  zIndexGlobalKeylineDivider: 50,
  zIndexLocalContext: 20,
  zIndexLocalForeground: 15,
  zIndexLocalMiddleground: 10,
  zIndexLocalBackground: 5,
  zIndexLocalNegate: -1,
  zIndexAbsoluteBottom: -999
};
