import { Controller } from 'stimulus';
import axios from 'axios';
import SmoothScroll from 'smooth-scroll';
import { easeOutExpo } from '../../utils/easings';
import {
  getUrlParams,
  turbolinksChangeUrl
} from '../../utils/urls';
import {
  scrollPosition,
  scrollOffset
} from '../../utils/scroll';

export default class extends Controller {
  static targets = [
    'filter',
    'select',
    'content',
    'scrollto'
  ]

  state = {
    loading: false,
    fontLoadTimeout: 250,
    component: 'experiences-grid',
    smoothScroll: new SmoothScroll(),
    activeClass: 'is-active',
    scrollTarget: (this.hasScrolltoTarget) ? this.scrolltoTarget : this.element,
    parser: new DOMParser(),
    activeFilter: false
  }

  connect() {
    const {
      state
    } = this;
    setTimeout(() => {
      this.refresh();
    }, state.fontLoadTimeout);
  }

  refresh() {
    this.checkScrollTo();
  }

  checkScrollTo() {
    const {
      state
    } = this;
    const params = getUrlParams();
    if ((params.scrollto) && (params.scrollto === state.component)) {
      this.scrollTo();
    }
  }

  scrollTo(delay = 250) {
    const {
      state
    } = this;
    const targetScrollPosition = state.scrollTarget.getBoundingClientRect().top + (scrollPosition() - scrollOffset());
    setTimeout(() => {
      state.smoothScroll.animateScroll(targetScrollPosition, false, {
        speed: (650 * 2),
        customEasing: (time) => easeOutExpo(time, 0, 1, 1)
      });
    }, delay);
  }

  filterChange(event) {
    const {
      state
    } = this;
    event.preventDefault();
    event.stopPropagation();
    const filter = event.currentTarget.value;
    if (!state.loading && filter !== state.activeFilter) {
      state.activeFilter = filter;
      this.replaceContent();
      this.setActiveFilter();
    }
  }

  filterClick(event) {
    const {
      state
    } = this;
    event.preventDefault();
    event.stopPropagation();
    const filter = event.currentTarget.getAttribute('href');
    if (!state.loading && filter !== state.activeFilter) {
      state.activeFilter = filter;
      this.replaceContent();
      this.setActiveFilter();
      this.setActiveSelect();
    }
  }

  replaceContent(url) {
    const {
      state
    } = this;
    if (!state.loading) {
      state.loading = true;
      turbolinksChangeUrl(state.activeFilter);
      axios
        .get(state.activeFilter)
        .then((response) => {
          this.parseAndAppendResponse(response);
        })
        .catch((error) => {
          console.error('Experiences grid : replaceContent : error', error);
          window.location.reload();
        });
    }
  }

  parseAndAppendResponse(response) {
    const {
      state
    } = this;
    const html = state.parser.parseFromString(response.data, 'text/html');
    const content = html.querySelector('[data-target="experiences-grid.content"]').innerHTML;
    this.contentTarget.innerHTML = content;
    state.loading = false;
  }

  setActiveFilter() {
    const {
      state
    } = this;
    this.filterTargets
      .forEach((node) => node.classList.remove(state.activeClass));
    this.filterTargets.filter((node) => node.getAttribute('href') === state.activeFilter)
      .forEach((node) => node.classList.add(state.activeClass));
  }

  setActiveSelect() {
    const {
      state
    } = this;
    this.selectTarget.value = state.activeFilter;
  }
}
