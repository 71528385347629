import { Controller } from "stimulus";
import axios from 'axios';

export default class extends Controller {

  connect() {
    this.reloadContent()
  }

  reloadContent() {
    let lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    axios
      .get(`/string_component/local_weather/?lang=${lang}`)
      .then((response) => {
        let html = document.createElement("html");
        html.innerHTML = response.data;
        this.element.innerHTML = html.querySelector('.local-weather').innerHTML
      })
      .catch((error) => {
        console.error('local_weather : reloadContent : error', error);
      });
  }

}
