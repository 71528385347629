import { Controller } from 'stimulus';
import loadJs from 'loadjs';
export default class extends Controller {
  static targets = [
  ]

  state = {
    loaded: (typeof ProcessNavisNCKeyword === 'function'),
    script: 'https://www.navistechnologies.com/JavascriptPhoneNumber/js.aspx',
    params: {
      account: this.data.get('account') || false,
      jspass: this.data.get('jspass') || false,
      dflt: this.data.get('dflt') || false,
    },
    pattern: this.data.get('pattern') || false,
    domain: this.data.get('domain') || false,
    bookingDomain: this.data.get('booking-domain') || false
  }

  connect() {
    const {
      state
    } = this;
    if (!state.loaded) {
      this.load();
    } else {
      this.setDynamicNumbers();
    }
  }

  load() {
    const {
      state
    } = this;
    const scriptUrl = `${state.script}?${this.urlParamsString()}`;
    loadJs([scriptUrl], () => {
      ProcessNavisNCKeyword();
      this.setDynamicNumbers();
    });
  }

  urlParamsString() {
    const {
      state
    } = this;
    return Object.keys(state.params).reduce((array, param) => {
      array.push(`${param}=${encodeURIComponent(state.params[param])}`);
      return array;
    }, []).join('&');
  }

  setDynamicNumbers() {
    const phoneElements = document.getElementsByClassName('navisTFN');
    const navisTFNraw = NavisConvertTagToPhoneNumberBasic(ReadNavisTagCookie());
    const navisTFNdisp = FormatPhone(navisTFNraw, '(###) ###-####');
    const navisTFNhref = FormatPhone(navisTFNraw, '###-###-####');
    for (let i = phoneElements.length - 1; i >= 0; --i) {
      const element = phoneElements[i];
      const display = element.getElementsByClassName('navisTFNdisp');
      element.href = `tel:+1-${navisTFNhref}`;
      display[0].innerHTML = navisTFNdisp;
    }
  }
}
