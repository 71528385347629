import { Controller } from 'stimulus';

export default class extends Controller {

  state = {
    active: false,
    activeClass: 'is-active'
  }

  connect() {
    const {
      state
    } = this;
  }

  open() {
    const {
      state
    } = this;
    if (!state.active) {
      this.element.classList.add(state.activeClass);
      state.active = true;
    }
  }

  close() {
    const {
      state
    } = this;
    if (state.active) {
      this.element.classList.remove(state.activeClass);
      state.active = false;
    }
  }

  checkOutsideClick(event) {
    const {
      state
    } = this;
    if (state.active) {
      const component = this.element;
      const isWithinComponent = component.contains(event.target);
      if (!isWithinComponent) {
        this.close();
      }
    }
  }
}
