import { Controller } from "stimulus";
import { parse, format } from 'date-fns';
import { startCase } from 'lodash';
import {
  isOldIE
} from '../../utils/support';

export default class extends Controller {
  static targets = [
    'form',
    'arrive',
    'depart',
    'adult',
    'child',
    'arrivedepart',
    'overlay'
  ]

  state = {
    format: {
      form: 'yyyy-MM-dd',
      human: 'MM/dd/yyyy',
    },
    booking: {
      arrive: this.arriveTarget.value || '',
      depart: this.departTarget.value || '',
      adult: this.adultTarget.value || '2',
      child: this.childTarget.value || '0'
    },
    isOldIE: isOldIE()
  }

  connect() {
    const {
      state
    } = this;
  }

  showCalendar() {
    const {
      state
    } = this;
    if (state.isOldIE) {
      this.formTarget.submit();
    } else {
      const customEvent = new CustomEvent('bookingCalendarShow');
      window.dispatchEvent(customEvent);
    }
  }

  update(event) {
    const {
      state
    } = this;
    const newState = event.detail;
    if (newState) {
      Object.keys(newState).forEach((key) =>{
        const value = newState[key];
        if (value !== state.booking[key]) {
          const method = `set${startCase(key)}`;
          if (typeof this[method] === 'function') {
            this[method](value);
          }
        }
      });
    }
  }

  setArrive(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.arrive = value;
      this.arriveTarget.value = state.booking.arrive;
      this.setArriveDepart();
    }
  }

  setDepart(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.depart = value;
      this.departTarget.value = state.booking.depart;
      this.setArriveDepart();
    }
  }

  setArriveDepart() {
    const {
      state
    } = this;
    const arrive = parse(state.booking.arrive, state.format.form, new Date());
    const depart = parse(state.booking.depart, state.format.form, new Date());
    const values = [format(arrive, state.format.human), format(depart, state.format.human)];
    this.arrivedepartTarget.innerHTML = values.join('—');
  }

  setAdult(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.adult) {
      state.booking.adult = value;
      this.adultTarget.value = state.booking.adult;
      if (event) this.notify();
    }
  }

  setChild(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.child) {
      state.booking.child = value;
      this.childTarget.value = state.booking.child;
      if (event) this.notify();
    }
  }

  notify() {
    const {
      state
    } = this;
    const customEvent = new CustomEvent('bookingFormUpdated', {
      detail: state.booking
    });
    window.dispatchEvent(customEvent);
  }
}
