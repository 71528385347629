import { Controller } from 'stimulus';
import globalVars from '../../config/global';
import {
  supportsTouch
} from '../../utils/support';
import {
  videoIsPlaying
} from '../../utils/media';
import {
  pxToInteger
} from '../../utils/data';

export default class extends Controller {
  static targets = [
    'container',
    'media',
    'multiline',
    'multilineStep'
  ]

  state = {
    delay: 125,
    fadeTimeout: 250,
    hasContainerTarget: this.hasContainerTarget,
    shouldSetViewportHeight: supportsTouch() && window.outerWidth <= pxToInteger(globalVars.breakpointTabletLandscape),
    shouldFade: (this.element.classList.contains('billboard--fade-headline')),
    hasMultiLineTarget: this.hasMultilineTarget,
    hasMediaTarget: this.hasMediaTarget,
    animation: {
      ticker: false,
      video: false,
      markers: [],
      currentIndex: 0,
      currentMarker: false,
      duration: parseInt((this.hasMultilineTarget) ? this.multilineTarget.dataset.duration : '0'),
      activeClass: 'is-active',
      steps: this.multilineStepTargets,
      total: this.multilineStepTargets.length,
      currentTime: false
    }
  }

  connect() {
    const {
      state
    } = this;
    if (state.shouldSetViewportHeight) {
      setTimeout(() => {
        this.setViewportHeight();
      }, state.delay);
      setTimeout(() => {
        this.setViewportHeight();
      }, state.delay * 2);
    }
    if (state.shouldFade) {
      this.setFade();
    }
    if (state.hasMediaTarget && state.hasMultiLineTarget) {
      const video = (this.hasMediaTarget) ? this.mediaTarget.querySelector('video') : false;
      state.animation.video = video;
      this.initAnimation();
    }
  }

  setViewportHeight() {
    const {
      state
    } = this;
    if (this.hasContainerTarget) {
      const target = Math.max(window.innerHeight, pxToInteger(globalVars.minBillboardHeight));
      this.containerTarget.style.height = `${target}px`;
    }
  }

  setFade() {
    this.element.classList.remove('billboard--fade-headline');
  }

  initAnimation() {
    const {
      state
    } = this;
    this.getMarkers();
    state.animation.video.addEventListener('timeupdate', this.tickVideo.bind(this));
  }

  getMarkers() {
    const {
      state
    } = this;
    const duration = Math.round(state.animation.duration);
    state.animation.markers = state.animation.steps.map((step, index) => {
      const part = duration / state.animation.total;
      return {
        index: index,
        start: part * index,
        finish: (part * index) + part,
        element: step
      }
    });
  }

  setMarker() {
    const {
      state
    } = this;
    const currentIndex = state.animation.currentIndex;
    state.animation.markers[currentIndex].element.classList.remove(state.animation.activeClass);
    state.animation.currentMarker.element.classList.add(state.animation.activeClass);
    state.animation.currentIndex = state.animation.currentMarker.index;
  }

  tickVideo(event) {
    const {
      state
    } = this;
    const currentTime = state.animation.video.currentTime;
    const isPlaying = videoIsPlaying(state.animation.video);
    if (isPlaying) {
      const currentMarker = state.animation.markers.filter((marker) => {
        return (currentTime >= marker.start) && (currentTime <= marker.finish);
      });
      const setCurrentMarker = (
        currentMarker.length && currentMarker[0].index !== state.animation.currentMarker.index
      ) ? currentMarker[0] : false;
      if (setCurrentMarker) {
        state.animation.currentMarker = currentMarker[0];
        this.setMarker();
      }
      state.currentTime = currentTime;
    }
  }
}
