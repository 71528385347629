import { Controller } from 'stimulus';
import { throttle, debounce } from 'lodash';
import globalVars from '../../config/global';
import { pxToInteger } from '../../utils/data';

export default class extends Controller {
  static targets = [
    'top',
    'drawer',
    'scroll',
    'burger',
    'draweraction',
    'activeSubnavigation'
  ]

  state = {
    tolerance: 64,
    scrolled: false,
    drawerOpen: false,
    scrollOffset: false,
    fontLoadTimeout: 250,
    width: window.outerWidth,
    scrolledClass: 'header--is-scrolled',
    hasTopTarget: this.hasTopTarget,
    hasActiveSubnavigationTarget: this.hasActiveSubnavigationTarget,
    drawerCloseAt: pxToInteger(globalVars.breakpointDesktopLarge),
    drawerOpenClass: 'header--is-drawer-open',
    closeMobileNavigaiton: pxToInteger(globalVars.breakpointDesktopLarge)
  }

  initialize() {
    this.onScroll = throttle(this.onScroll, this.state.tolerance);
    this.onResize = debounce(this.onResize, this.state.tolerance);

    if (this.hasActiveSubnavigationTarget) {
      this.scrollToActiveSubnavigation();
    }
  }

  connect() {
    this.setScrollOffset();
    this.checkScrollPosition();
  }

  onScroll(event) {
    this.checkScrollPosition();
  }

  onResize(event) {
    const {
      state
    } = this;
    const width = window.outerWidth;
    const shouldUpdate = (width !== state.width);
    if (shouldUpdate) {
      this.checkWindowWidth();
      state.width = width;
    }
  }

  onKeydown(event) {
    const {
      state
    } = this;
    const { keyCode } = event;
    if (state.drawerOpen && keyCode === 27) {
      this.closeDrawer();
    }
  }

  setScrollOffset() {
    const {
      state
    } = this;
    const offset = (this.hasTopTarget) ? this.topTarget.offsetHeight : 100;
    state.scrollOffset = offset;
  }

  checkScrollPosition() {
    const {
      state
    } = this;
    const scrollPosition = window.pageYOffset;
    const scrolledPassed = scrollPosition > state.scrollOffset;
    if (!state.scrolled && scrolledPassed) {
      state.scrolled = true;
      this.element.classList.add(state.scrolledClass);
    }
    if (state.scrolled && !scrolledPassed) {
      state.scrolled = false;
      this.element.classList.remove(state.scrolledClass);
    }
  }

  checkWindowWidth() {
    const {
      state
    } = this;
    const windowWidth = window.outerWidth;
    if (state.drawerOpen && windowWidth > state.drawerCloseAt) {
      this.closeDrawer();
    }
  }

  toggleDrawer() {
    const {
      state
    } = this;
    if (state.drawerOpen) {
      this.closeDrawer();
    } else {
      this.openDrawer();
    }
  }

  openDrawer() {
    const {
      state
    } = this;
    state.drawerOpen = true;
    this.element.classList.add(state.drawerOpenClass);
    setTimeout(() => {
      this.draweractionTargets[0].focus();
    }, 125);
  }

  closeDrawer() {
    const {
      state
    } = this;
    state.drawerOpen = false;
    this.element.classList.remove(state.drawerOpenClass);
  }

  scrollToActiveSubnavigation() {
    const {
      state
    } = this;
    setTimeout(() => {
      this.activeSubnavigationTarget.scrollIntoView({
        behavior: 'smooth',
        inline: 'center'
      });
    }, state.fontLoadTimeout);
  }
}
