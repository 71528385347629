import { Controller } from 'stimulus';
import loadJs from 'loadjs';
import { debounce } from 'lodash';
import Flickity from 'flickity';
import {
  supportsIntersectionObserver
} from '../../utils/support';

export default class extends Controller {
  static targets = [
    'content',
    'carousel'
  ]

  state = {
    try: 5,
    once: false,
    tolerance: 250,
    instance: false,
    loading: true,
    loadingClass: 'is-loading',
    loaded: (window.Juicer !== undefined),
    style: '//assets.juicer.io/embed.css',
    script: '//assets.juicer.io/embed.js',
    target: false,
    supportsIntersectionObserver: supportsIntersectionObserver(),
    observer: false,
    width: window.outerWidth,
    observerConfig: {
      rootMargin: '100%',
      threshold: 0.01
    }
  }

  initialize() {
    this.onResize = debounce(this.refresh, this.state.tolerance);
  }

  connect() {
    const {
      state
    } = this;
    if (!state.loaded && state.supportsIntersectionObserver) {
      this.observe();
    } else if (!state.loaded) {
      this.load();
    } else {
      this.initJuicer();
    }
  }

  observe() {
    const {
      state
    } = this;
    state.observer = new IntersectionObserver(this.onIntersection.bind(this), state.observerConfig);
    [this.element].forEach((element) => state.observer.observe(element));
  }

  onIntersection(entries) {
    const {
      state
    } = this;
    if (!state.once) {
      const entry = entries[0];
      const inview = entry.intersectionRatio > 0;
      if (inview) {
        this.load();
        state.once = true;
        state.observer.unobserve(entry.target);
      }
    }
  }

  load() {
    const {
      state
    } = this;
    loadJs([state.style, state.script], () => {
      this.initJuicer();
    });
  }

  refresh() {
    const {
      state
    } = this;
    const width = window.outerWidth;
    const shouldUpdate = (width !== state.width);
    if (!state.loading && shouldUpdate) {
      this.setLoading();
      this.findCarousel();
      state.width = width;
    }
  }

  initJuicer() {
    if (typeof window.Juicer.initialize === 'function') {
      window.Juicer.initialize();
      this.findCarousel();
    }
  }

  findCarousel() {
    const {
      state
    } = this;
    const target = this.carouselTarget.querySelectorAll('.j-stack.galcolumn');
    if (target[0]) {
      state.target = target[0];
      this.initCarousel();
    } else if (state.try > 0) {
      setTimeout(() => {
        this.findCarousel();
      }, state.tolerance);
      state.try -= 1;
    } else {
      console.warn('Social Carousel : cannot find carousel target', this);
    }
  }

  initCarousel() {
    const {
      state
    } = this;
    if (state.instance) {
      state.instance.destroy();
    }
    state.instance = new Flickity(state.target, {
      prevNextButtons: false,
      cellAlign: 'center',
      setGallerySize: false,
      freeScroll: false,
      wrapAround: true,
      contain: false,
      pageDots: false,
      adaptiveHeight: false,
      selectedAttraction: 0.0275,
      friction: 0.3
    });
    setTimeout(() => {
      state.instance.resize();
      this.setLoaded();
    }, state.tolerance);
    state.try = 5;
  }

  setLoaded() {
    const {
      state
    } = this;
    this.contentTarget.classList.remove(state.loadingClass);
    state.loading = false;
  }

  setLoading() {
    const {
      state
    } = this;
    this.contentTarget.classList.add(state.loadingClass);
    state.loading = true;
  }
}
