import { Controller } from 'stimulus';
import Flickity from 'flickity';

export default class extends Controller {
  static targets = [
    'card'
  ]

  state = {
    instance: false,
  };

  connect() {
    this.initSlider();
  }

  initSlider() {
    const {
      state,
    } = this;
    const slides = this.cardTargets.length - 1;
    const index = 0;
    state.instance = new Flickity(this.element, {
      prevNextButtons: false,
      cellAlign: 'center',
      freeScroll: false,
      wrapAround: true,
      contain: false,
      pageDots: false,
      adaptiveHeight: false,
      selectedAttraction: 0.0275,
      friction: 0.3,
      initialIndex: index
    });
    state.instance.on('select', (slideIndex) => {
      this.setTabIndexAttributeOnCells(state.instance, slideIndex);
    });
  }


  setTabIndexAttributeOnCells(instance, index) {
    instance.cells.forEach((cell, i) => {
      if (index === i) {
        cell.element.removeAttribute('tabindex');
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.element.tabIndex = -1;
      }
    });
  }
}
