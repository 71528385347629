import { Controller } from 'stimulus';
import Flickity from 'flickity-fade';

export default class extends Controller {
  static targets = ['carousel', 'dots', 'dot'];

  state = {
    index: 0,
    instance: false,
    activeClass: 'is-active'
  };

  connect() {
    this.initCarousel();
  }

  initCarousel() {
    const {
      state,
    } = this;
    state.carousel = new Flickity(this.carouselTarget, {
      fade: true,
      prevNextButtons: false,
      cellAlign: 'center',
      freeScroll: false,
      wrapAround: false,
      contain: false,
      pageDots: false,
      autoPlay: true
    });
    state.carousel.on('select', (index) => {
      this.setStateIndex(index);
      this.setActiveDot();
    });
    setTimeout(() => {
      state.carousel.resize();
    }, 250);
  }

  setStateIndex(index) {
    const {
      state,
    } = this;
    state.index = index;
  }

  setActiveDot() {
    const {
      state,
    } = this;
    this.dotTargets
      .forEach((node) => node.classList.remove(state.activeClass));
    this.dotTargets
      .filter((node) => node.getAttribute('data-index') === `${state.index}`)
      .forEach((node) => node.classList.add(state.activeClass));
  }

  goToindex(event) {
    const {
      state,
    } = this;
    const index = event.currentTarget.getAttribute('data-index');
    if (state.carousel) {
      state.carousel.select(index, true);
      state.index = index;
    }
  }
}
