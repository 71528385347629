import { Controller } from 'stimulus';
import globalVars from '../../config/global';
import {
  supportsTouch
} from '../../utils/support';
import { pxToInteger } from '../../utils/data';

export default class extends Controller {
  static targets = ['container']

  state = {
    hasContainerTarget: this.hasContainerTarget,
    innerHeight: (window.innerHeight > pxToInteger(globalVars.minBillboardHeight)) ? window.innerHeight : pxToInteger(globalVars.minBillboardHeight),
    shouldSetViewportHeight: supportsTouch() && window.outerWidth <= pxToInteger(globalVars.breakpointTabletLandscape),
  }

  connect() {
    const {
      state
    } = this;
    if (state.shouldSetViewportHeight) {
      this.setViewportHeight();
    }
  }

  setViewportHeight() {
    const {
      state
    } = this;
    if (this.hasContainerTarget) {
      this.containerTarget.style.height = `${state.innerHeight}px`;
    }
  }
}
