import application from 'stimulus_application';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import './header.css';
import './header_spacer.css';
import './header_gradient.css';
import './header_burger.css';
import './header_top.css';
import './header_drawer.css';
import './header_scroll.css';

const context = require.context('./', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
