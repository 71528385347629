import { Controller } from "stimulus";
import { parse, format } from 'date-fns';
import { startCase } from 'lodash';
import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = [
    'datepicker',
    'close',
    'form',
    'arrive',
    'depart',
    'adult',
    'child',
    'arrivedate',
    'departdate'
  ]

  state = {
    active: false,
    activeClass: 'is-active',
    instance: false,
    updating: false,
    format: {
      form: 'yyyy-MM-dd',
      human: {
        date: 'MMM dd'
      }
    },
    config: {
      inline: true,
      showMonths: 2,
      mode: 'range',
      clickOpens: false,
      minDate: this.datepickerTarget.getAttribute('data-min-date') || 'today',
      maxDate: this.datepickerTarget.getAttribute('data-max-date') || false,
      defaultDate: JSON.parse(this.datepickerTarget.getAttribute('data-selected-dates')) || [],
      dateFormat: 'Y-m-d',
      closeOnSelect: false,
      monthSelectorType: 'static'
    },
    booking: {
      arrive: this.arriveTarget.value || '',
      depart: this.departTarget.value || '',
      adult: this.adultTarget.value || '2',
      child: this.childTarget.value || '0',
    }
  }

  connect() {
    this.initDatePicker();
  }

  initDatePicker() {
    const {
      state
    } = this;
    state.instance = flatpickr(this.datepickerTarget, state.config);
    state.instance.open();
    state.instance.config.onChange.push((selectedDates, dateStr) => this.change(selectedDates, dateStr));
  }

  show(event) {
    const {
      state
    } = this;
    if (!state.active) {
      state.active = true;
      this.element.classList.add(state.activeClass);
    }
  }

  onKeydown(event) {
    const {
      state
    } = this;
    const { keyCode } = event;
    if (state.active && keyCode === 27) {
      this.forceClose();
    }
  }

  close(event) {
    const {
      state
    } = this;
    if (state.active && event.target === this.closeTarget) {
      state.active = false;
      this.element.classList.remove(state.activeClass);
    }
  }

  forceClose(event) {
    const {
      state
    } = this;
    if (state.active) {
      state.active = false;
      this.element.classList.remove(state.activeClass);
    }
  }

  change(selectedDates, dateString) {
    const {
      state
    } = this;
    if (!state.updating) {
      state.updating = true;
      selectedDates.forEach((date, index) => {
        const value = format(date, state.format.form);
        if (index === 0) this.setArrive(value);
        if (index === 1) this.setDepart(value);
      });
      state.updating = false;
      this.notify();
    }
  }

  update(event) {
    const {
      state
    } = this;
    const newState = event.detail;
    Object.keys(newState).forEach((key) => {
      const value = newState[key];
      if (value !== state.booking[key]) {
        const method = `set${startCase(key)}`;
        if (typeof this[method] === 'function') {
          this[method](value);
        }
      }
    });
  }

  setArrive(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.arrive = value;
      this.arriveTarget.value = state.booking.arrive;
      this.setArriveDate();
    }
  }

  setDepart(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.depart = value;
      this.departTarget.value = state.booking.depart;
      this.setDepartDate();
    }
  }

  setAdult(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.adult) {
      state.booking.adult = value;
      this.adultTarget.value = state.booking.adult;
      if (event) this.notify();
    }
  }

  setChild(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.child) {
      state.booking.child = value;
      this.childTarget.value = state.booking.child;
      if (event) this.notify();
    }
  }

  setArriveDate() {
    const {
      state
    } = this;
    const date = parse(state.booking.arrive, state.format.form, new Date());
    const formatted = format(date, state.format.human.date);
    this.arrivedateTarget.innerHTML = formatted;
  }

  setDepartDate() {
    const {
      state
    } = this;
    const date = parse(state.booking.depart, state.format.form, new Date());
    const formatted = format(date, state.format.human.date);
    this.departdateTarget.innerHTML = formatted;
  }

  notify() {
    const {
      state
    } = this;
    const customEvent = new CustomEvent('bookingFormUpdated', {
      detail: state.booking
    });
    window.dispatchEvent(customEvent);
  }
}
