import { Controller } from "stimulus";
import { startCase } from 'lodash';
import { parse, format } from 'date-fns';
import {
  isOldIE
} from '../../utils/support';

export default class extends Controller {
  static targets = [
    'form',
    'arrive',
    'depart',
    'adult',
    'child',
    'arrivemonth',
    'arriveday',
    'departmonth',
    'departday',
    'overlay'
  ]

  state = {
    format: {
      form: 'yyyy-MM-dd',
      human: {
        day: 'dd',
        month: 'MMM'
      }
    },
    booking: {
      arrive: this.arriveTarget.value || '',
      depart: this.departTarget.value || '',
      adult: this.adultTarget.value || '2',
      child: this.childTarget.value || '0',
    },
    isOldIE: isOldIE()
  }

  connect() {
    const {
      state
    } = this;
  }

  showCalendar() {
    const {
      state
    } = this;
    if (state.isOldIE) {
      this.formTarget.submit();
    } else {
      const customEvent = new CustomEvent('bookingCalendarShow');
      window.dispatchEvent(customEvent);
    }
  }

  update(event) {
    const {
      state
    } = this;
    const newState = event.detail;
    Object.keys(newState).forEach((key) => {
      const value = newState[key];
      if (value !== state.booking[key]) {
        const method = `set${startCase(key)}`;
        if (typeof this[method] === 'function') {
          this[method](value);
        }
      }
    });
  }

  setArrive(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.arrive = value;
      this.arriveTarget.value = state.booking.arrive;
      this.setArriveTargets();
    }
  }

  setDepart(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.arrive) {
      state.booking.depart = value;
      this.departTarget.value = state.booking.depart;
      this.setDepartTargets();
    }
  }

  setAdult(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.adult) {
      state.booking.adult = value;
      this.adultTarget.value = state.booking.adult;
    }
  }

  setChild(update) {
    const {
      state
    } = this;
    const event = (update.currentTarget);
    const value = (event) ? update.currentTarget.value : update;
    if (value !== state.booking.child) {
      state.booking.child = value;
      this.childTarget.value = state.booking.child;
    }
  }

  setArriveTargets() {
    const {
      state
    } = this;
    const date = parse(state.booking.arrive, state.format.form, new Date());
    const day = format(date, state.format.human.day);
    const month = format(date, state.format.human.month);
    this.arrivedayTarget.innerHTML = day;
    this.arrivemonthTarget.innerHTML = month;
  }

  setDepartTargets() {
    const {
      state
    } = this;
    const date = parse(state.booking.depart, state.format.form, new Date());
    const day = format(date, state.format.human.day);
    const month = format(date, state.format.human.month);
    this.departdayTarget.innerHTML = day;
    this.departmonthTarget.innerHTML = month;
  }
}
