import { Controller } from 'stimulus';
import globalVars from '../../config/global';
import {
  supportsObjectFit,
  supportsIntersectionObserver
} from '../../utils/support';
import { pxToInteger } from '../../utils/data';


export default class extends Controller {
  static targets = ['object', 'video', 'hero', 'image']

  state = {
    src: false,
    once: false,
    loaded: false,
    loadedTimeout: 65,
    loadedClass: 'is-loaded',
    isHero: this.hasHeroTarget,
    hasImage: this.hasImageTarget,
    hasVideo: this.hasVideoTarget,
    supportsObjectFit: supportsObjectFit(),
    supportsIntersectionObserver: supportsIntersectionObserver(),
    shouldLoadVideo: window.outerWidth > pxToInteger(globalVars.breakpointTablet),
    shouldLoadMobileVideo: window.outerWidth <= pxToInteger(globalVars.breakpointMobile),
    observer: false,
    observerConfig: {
      rootMargin: '250px',
      threshold: 0.01
    }
  }

  connect() {
    const {
      state
    } = this;
    if (state.supportsObjectFit) {
      this.observe();
    } else {
      this.load();
    }
  }

  observe() {
    const {
      state
    } = this;
    state.observer = new IntersectionObserver(this.onIntersection.bind(this), state.observerConfig);
    [this.element].forEach((element) => state.observer.observe(element));
  }

  onIntersection(entries) {
    const {
      state
    } = this;
    if (!state.once) {
      const entry = entries[0];
      const inview = entry.intersectionRatio > 0;
      if (inview) {
        this.load();
        state.once = true;
        state.observer.unobserve(entry.target);
      }
    }
  }

  load() {
    const {
      state
    } = this;
    if (state.hasImage) {
      const src = this.imageTarget.getAttribute('data-src') || false;
      const srcset = this.imageTarget.getAttribute('data-srcset') || false;
      if (src && srcset) {
        this.imageTarget.addEventListener('load', () => {
          this.update();
          this.setLoaded();
        });
        this.imageTarget.setAttribute('src', src);
        this.imageTarget.setAttribute('srcset', srcset);
      }
    }
    if (state.supportsObjectFit && state.hasVideo) {
      if (state.isHero) {
        this.loadVideo();
      } else if (state.shouldLoadVideo) {
        this.loadVideo();
      } else {
        this.removeVideo();
      }
    } else {
      this.removeVideo();
    }
  }

  loadVideo() {
    const {
      state
    } = this;
    const sources = this.videoTarget.getElementsByTagName('source');
    Array.from(sources).forEach((source) => {
      const src = source.getAttribute('data-src');
      const mobileSrc = source.getAttribute('data-mobile-src');
      if (state.shouldLoadMobileVideo && mobileSrc) {
        source.setAttribute('src', mobileSrc);
        source.removeAttribute('data-mobile-src');
        this.playVideo();
      } else if (src) {
        source.setAttribute('src', src);
        source.removeAttribute('data-src');
        this.playVideo();
      }
    });
  }

  playVideo() {
    const video = this.videoTarget;
    const playPromise = video.play();
    video.load();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        video.pause();
      }).catch((error) => {
        console.error('Media : playVideo', error);
      });
    }
  }

  removeVideo() {
    const {
      state
    } = this;
    if (state.hasVideo && state.hasVideoTarget) {
      this.videoTarget.remove();
      this.videoTarget = false;
    }
  }

  update() {
    const {
      state
    } = this;
    const src = typeof this.imageTarget.currentSrc !== 'undefined' ? this.imageTarget.currentSrc : this.imageTarget.src;
    if (state.src !== src) {
      state.src = src;
      this.objectTarget.style.backgroundImage = `url(${state.src})`;
    }
  }

  setLoaded() {
    const {
      state
    } = this;
    if (!state.loaded) {
      state.loaded = true;
      setTimeout(() => {
        this.element.classList.add(state.loadedClass);
      }, state.loadedTimeout);
    }
  }
}
