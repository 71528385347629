
const scrollPosition = () => {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  return scrollPosition;
};

const scrollOffset = () => {
  const offset = (document.querySelectorAll('[data-target="header.scroll"]')[0]) ? document.querySelectorAll('[data-target="header.scroll"]')[0].offsetHeight : 0;
  return offset;
};

export {
  scrollPosition,
  scrollOffset
};
