import {
  supportsTouch
} from '../utils/support';
import './index.css';

(function init() {
  const html = document.getElementsByTagName('html')[0];
  const touchDevice = supportsTouch();
  if (touchDevice) {
    html.classList.add('is-touch-device');
  } else {
    html.classList.add('not-touch-device');
  }
  html.classList.remove('no-transitions');
}());
