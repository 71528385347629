import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = [
    'close',
    'card'
  ]
  focusableSelectors = 'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])';

  state = {
    active: false,
    activeClass: 'is-active',
    timeout: parseInt(this.data.get('timeout'), 10) || 2000,
    cookie: {
      initial: Cookies.get('calloutPopupShown'),
      name: 'calloutPopupShown',
      value: true
    }
  }

  connect() {
    this.initPopup();
  }

  initPopup() {
    this.element.ariaHidden = true
    const {
      state
    } = this;
    if (!state.cookie.initial) {
      setTimeout(() => {
        this.show();
      }, state.timeout);
    }
  }

  onKeydown(event) {
    const {
      state
    } = this;
    const { keyCode } = event;
    if (state.active && keyCode === 27) {
      this.forceClose();
    }
  }

  show() {
    const {
      state
    } = this;
    if (!state.active) {
      state.active = true;
      this.element.classList.add(state.activeClass);
      this.element.ariaHidden = false;
      this.setFocusToFirstElement();
      this.element.addEventListener('keydown', this.handleKeyDown);
    }
  }

  close(event) {
    const {
      state
    } = this;
    if (state.active && (event.target === this.closeTarget || event.target === this.cardTarget)) {
      state.active = false;
      this.element.classList.remove(state.activeClass);
      this.element.ariaHidden = true
      this.setCookie();
      this.removeFocusTrap();
    }
  }

  forceClose() {
    const {
      state
    } = this;
    if (state.active) {
      state.active = false;
      this.element.classList.remove(state.activeClass);
      this.element.ariaHidden = true
      this.setCookie();
      this.removeFocusTrap();
    }
  }

  setFocusToFirstElement() {
    const focusableElements = this.element.querySelectorAll(this.focusableSelectors);
    if (focusableElements.length) focusableElements[0].focus();
  }

  handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      this.trapFocus(event);
    }

    if (event.key === 'Escape') {
      this.forceClose();
    }
  }

  trapFocus(event) {
    const focusableElements = Array.from(this.element.querySelectorAll(this.focusableSelectors));
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (event.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      event.preventDefault();
    } else if (!event.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      event.preventDefault();
    }
  }

  removeFocusTrap() {
    this.element.removeEventListener('keydown', this.handleKeyDown);
  }

  setCookie() {
    const {
      state
    } = this;
    Cookies.set(state.cookie.name, state.cookie.value, { path: '/' });
  }
}
