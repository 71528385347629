import { Controller } from 'stimulus';
import Flickity from 'flickity';

export default class extends Controller {
  static targets = ['carousel', 'dots', 'dot'];

  state = {
    index: 0,
    instance: false,
    activeClass: 'is-active'
  };

  connect() {
    this.initCarousel();
  }

  initCarousel() {
    const {
      state,
    } = this;
    state.carousel = new Flickity(this.carouselTarget, {
      prevNextButtons: false,
      setGallerySize: false,
      cellAlign: 'right',
      freeScroll: false,
      wrapAround: false,
      contain: false,
      pageDots: false,
      adaptiveHeight: false,
      selectedAttraction: 0.0275,
      friction: 0.3
    });
    state.carousel.on('select', (index) => {
      this.setStateIndex(index);
      this.setActiveDot();
      this.setTabIndexAttributeOnCells(state.carousel, index);
    });
  }

  setStateIndex(index) {
    const {
      state,
    } = this;
    state.index = index;
  }

  setActiveDot() {
    const {
      state,
    } = this;
    this.dotTargets
      .forEach((node) => node.classList.remove(state.activeClass));
    this.dotTargets
      .filter((node) => node.getAttribute('data-index') === `${state.index}`)
      .forEach((node) => node.classList.add(state.activeClass));
  }

  setTabIndexAttributeOnCells(instance, index) {
    instance.cells.forEach((cell, i) => {
      if (index === i) {
        cell.element.removeAttribute('tabindex');
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.element.tabIndex = -1;
      }
    });
  }

  goToindex(event) {
    const {
      state,
    } = this;
    const index = event.currentTarget.getAttribute('data-index');
    if (state.carousel) {
      state.carousel.select(index, true);
      state.index = index;
    }
  }
}
