import { Controller } from 'stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = [
  ]

  state = {
    active: false,
    activeClass: 'is-active',
    cookie: {
      initial: Cookies.get('cookieBannerAccepted'),
      name: 'cookieBannerAccepted',
      value: true
    }
  }

  connect() {
    this.initCookieBanner();
  }

  initCookieBanner() {
    const {
      state
    } = this;
    if (!state.cookie.initial) {
      setTimeout(() => {
        this.show();
      }, state.timeout);
    } else {
      this.close();
    }
  }

  show() {
    const {
      state
    } = this;
    if (!state.active) {
      state.active = true;
      this.element.classList.add(state.activeClass);
    }
  }

  close() {
    const {
      state
    } = this;
    if (state.active) {
      state.active = false;
      this.element.classList.remove(state.activeClass);
      this.remove();
      this.setCookie();
    }
  }

  remove() {
    const {
      state
    } = this;
    this.element.parentNode.removeChild(this.element);
  }

  setCookie() {
    const {
      state
    } = this;
    Cookies.set(state.cookie.name, state.cookie.value, { expires: 365, path: '/' });
  }
}
