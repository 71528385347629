// Builds query string from form data with no special processing
export function formParams(form) {
  return filteredFormInputs(form)
    .map(
      input =>
        `${encodeURIComponent(input.name)}=${encodeURIComponent(input.value)}`
    )
    .join("&");
}

function filteredFormInputs(form) {
  return Array.from(form.elements).filter(
    field =>
      field.name &&
      !field.disabled &&
      field.type !== "file" &&
      field.type !== "reset" &&
      field.type !== "submit" &&
      field.type !== "button" &&
      (field.checked || ["radio", "checkbox"].indexOf(field.type) == -1)
  );
}
